<template>
  <div id="landing">
      <div class="image">
        <img src="../assets/BWPortrait.jpg" alt="Chris Crisson">
        <h1>Chris Crisson</h1>
        <h3>Software Developer</h3>
        <p>crisson.christopher@gmail.com</p>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
#landing {
    display: flex;
    justify-content: center;
}
.image {
    width: 250px;
    height: auto;
}
.image img {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 50%;

}
</style>